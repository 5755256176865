<template>
    <v-expansion-panels :key="componentKey" v-model="activePanelIndexes" flat multiple>
        <draggable
            v-model="pages" v-bind="dragOptions"
            class="no-gutters row--dense align-center justify-center"
            tag="v-row">
            <kurcc-question-page
                v-for="(page, index) in pages" :key="page.id"
                :is-open="isOpen(index)"
                :page-have-questions="pageHaveQuestions(page)"
                :page-index="index"
                @open-panel="openPanel($event)"
                @close-panel="closePanel($event)">
            </kurcc-question-page>
        </draggable>
    </v-expansion-panels>
</template>

<script>
import {UPDATE_INSPECTION_FORM_FIELD} from "@/modules/app/store/mutation-types";

export default {
    name: "KurccQuestionPageList",
    components: {
        KurccQuestionPage: () => import('@/modules/forms/components/question/KurccQuestionPage'),
        draggable: () => import('vuedraggable')
    },
    data() {
        return {
            activePanelIndexes: [],
            componentKey: new Date().getTime()
        }
    },
    computed: {
        form() {
            return this.$store.state.forms.form
        },
        pages: {
            get() {
                return this.form.pages
            },
            set(pages) {
                this.$store.commit(UPDATE_INSPECTION_FORM_FIELD, {field: 'pages', value: pages})
            }
        },
        dragOptions() {
            return {
                animation: 0,
                group: {
                    name: 'page',
                    // pull: true,
                    // put: ['page']
                    // put: function (to, from) {
                    //     console.log('to: ', to)
                    //     console.log('from: ', from)
                    //     console.log(from.el.children.length)
                    //     return ['check'];
                    // }
                },
                disabled: false,
                ghostClass: "ghost",
                handle: ".drag-handle",
            };
        },

    },
    watch: {
        pages: function () {
            this.componentKey++
        }
    },
    methods: {
        pageHaveQuestions(page) {
            return page.questions.length > 0
        },
        openPanel(index) {
            const i = this.findIndexInActivePanels(index)
            if (i === -1)
                this.activePanelIndexes.push(index)
        },
        closePanel(index) {
            const i = this.findIndexInActivePanels(index)
            this.activePanelIndexes.splice(i, 1)
        },
        findIndexInActivePanels: function (index) {
            return this.$lodash.findIndex(this.activePanelIndexes, panelIndex => panelIndex === index);
        },
        isOpen(index) {
            return this.$lodash.find(this.activePanelIndexes, panelIndex => panelIndex === index) !== undefined
        }
    },
}
</script>

<style lang="scss" scoped>
.page {
    display: contents;
}
</style>
